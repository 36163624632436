<script lang="ts" setup>
import { Icon } from "@iconify/vue";

import InternalButton from "./Buttons/InternalButton.vue";
import UserStatusButtons from "./Buttons/UserStatusButtons.vue";
import type { ClaimFiltered } from "~/types";

const { claim } = defineProps<{
  claim: ClaimFiltered;
  reduced?: boolean;
}>();
const route = useRoute();
const active = computed(() => route.path.includes(claim.id));
const claimStore = useClaimStore();
const icon = computed(() => {
  switch (claim.ratingLabelName) {
    case "correct":
      return ICONS.correct;
    case "false":
      return ICONS.false;
    case "misleading":
      return ICONS.misleading;
    default:
      return "";
  }
});
</script>

<template>
  <BasicCard
    :to="`/claims/${route.params.filter}/${claim.id}${route.query.search ? `?search=${route.query.search}` : ''}`"
    :class="
      cn({
        'p-2.5 text-color-5 hover:text-color-6': reduced,
        '!border-online group': active
      })
    "
    @click="claimStore.setClaim(claim, 'claims')"
  >
    <div class="flex gap-1 items-start">
      <div class="flex gap-1 items-center">
        <UnreadIcon :hasRead="claim.userClaimStatuses[0]?.hasRead" />
        <h3
          :class="
            cn('h2 py-0.25 px-1.5 rounded-md border', {
              'border-online text-color-8': active
            })
          "
        >
          #{{ claim.processId }}
        </h3>
      </div>
      <CategoriesCard
        :categories="claim.categories"
        :badgeClass="cn({ 'border-strong group-hover:border-strong': active })"
      />
      <div class="ml-auto flex gap-1">
        <InternalButton :claimId="claim.id" :internal="claim.internal" size="xs" />
        <UserStatusButtons
          v-if="claim.id"
          :claimId="claim.id"
          :userStatus="claim.userClaimStatuses[0]"
          size="xs"
          showHasRead
        />
      </div>
    </div>
    <p :class="cn('base grow overflow-hidden', { '!text-sm': reduced })">
      <!-- <RatingLabel v-if="ratingLabelName" :label="ratingLabelName" size="xs" class="float-left" /> -->
      <Icon
        v-if="icon"
        :icon="icon"
        :class="
          cn('size-5 rounded-full float-left py-[0.2em] mr-1.5 -mb-0.5 text-color-1', {
            'bg-success': claim.ratingLabelName === 'correct',
            'bg-error': claim.ratingLabelName === 'false',
            'bg-warning': claim.ratingLabelName === 'misleading'
          })
        "
      />

      {{ claim.synopsis }}
    </p>
    <CreatedUpdated
      v-if="!reduced"
      :createdAt="claim.createdAt"
      :createdByUser="claim.createdByUser"
      :lastUpdate="claim.lastUpdate"
      minimized
    />
  </BasicCard>
</template>
