import { type VariantProps, cva } from "class-variance-authority";

export { default as Badge } from "./index.vue";

export const badgeVariants = cva(
  cn(
    "inline-flex items-center border min-h-5 py-1 items-center text-xs transition-colors",
    "focus:outline-hidden focus:ring-2 focus:ring-ring focus:ring-offset-2"
  ),
  {
    variants: {
      variant: {
        default: "rounded-full px-1.5 leading-[0.8]",
        square: "rounded-md px-1.5 leading-[0.8]"
      },
      color: {
        default: "group-hover:border-strong",
        highlighted: "bg-color-6 border-color-6 text-color-1",
        unread: "bg-error !border-error dark:text-color-1"
      }
    },
    defaultVariants: {
      variant: "default",
      color: "default"
    }
  }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;
